import { Space, Image, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import FlagJP from 'assets/images/ja.svg';
import i18n from 'i18n';
import { useAppDispatch, useAppSelector } from 'store';
import { changeLanguage } from 'providers/LangProvider/slice';
import FlagEN from 'assets/images/gb.svg';

const BlankLayoutContainer = ({
  children,
  path,
}: {
  children: JSX.Element;
  path: string;
}): JSX.Element => {
  const lang = useAppSelector((state) => state.lang);
  const dispatch = useAppDispatch();
  const handleChangeLanguage = (langValue) => {
    i18n.changeLanguage(langValue);
    dispatch(changeLanguage({ current: langValue }));
  };

  const menu = (
    <Menu
      onClick={(e) => handleChangeLanguage(e.key)}
      items={[
        {
          label: (
            <Space>
              <Image width={20} preview={false} src={FlagJP} /> <span>日本語</span>
            </Space>
          ),
          key: 'ja',
        },
        {
          label: (
            <Space>
              <Image width={20} preview={false} src={FlagEN} /> <span>English</span>
            </Space>
          ),
          key: 'en',
        },
      ]}
    />
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={['click']} className="box-language">
        <Space className="pointer">
          <Image width={20} preview={false} src={lang.current === 'ja' ? FlagJP : FlagEN} />
          <DownOutlined style={{ fontSize: '10px', color: '#000' }} />
        </Space>
      </Dropdown>
      {children}
    </div>
  );
};
export default BlankLayoutContainer;
