import { ComponentType, useEffect, useState } from 'react';
import { Route, RouteComponentProps, useHistory } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from 'admin-console-library';
// eslint-disable-next-line camelcase
import jwt_decode, { JwtPayload } from 'jwt-decode';
import LayoutPage from 'containers/Layout';
import sec from 'utils/security';
import LoginPage from 'containers/LoginPage';
import { AUTH0_NAMESPACE } from 'utils/config';
import { useAppDispatch, useAppSelector } from 'store';
import { setIsCheckPermission } from 'providers/GeneralProvider/slice';
interface ProtectedRouteType {
  component: ComponentType<RouteComponentProps>;
  exact: boolean | undefined;
  path: string;
}

const ProtectedRoute = ({ component, path, exact }: ProtectedRouteType): JSX.Element => {
  const { t } = useTranslation('loading');
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, logout } =
    useAuth0();
  const history = useHistory();
  const isCheckPermiss = useAppSelector((state) => state.general.isCheckPermiss);
  const dispatch = useAppDispatch();
  /**
   * Using `element` props of <Route> in React Router v6 is essensially a `render()` function.
   * Since we're using HOC here, we must declare Component only once to prevent React
   * from considering this Component different after each render. Otherwise, it will do remount every render.
   *
   * Ref: https://reactjs.org/docs/higher-order-components.html#dont-use-hocs-inside-the-render-method
   *
   * The unnecessary remount makes poor performance, lost all states of this component and its children,
   * causing many part to reload data. UX is also bad.
   */
  const [Component] = useState(() => {
    return withAuthenticationRequired(component, {
      onRedirecting: function renderRedirect() {
        return <div>{t('loading')}...</div>;
      },
    });
  });

  useEffect(() => {
    sec.setLoginWithRedirect(loginWithRedirect);
    sec.setLogout(logout);
    if (isAuthenticated) {
      checkAuth();
      sec.setAccessTokenSilently(getAccessTokenSilently);
    }
  }, [isAuthenticated]);

  const checkAuth = async () => {
    try {
      const token = await getAccessTokenSilently({});
      const auth = jwt_decode<JwtPayload>(token);
      const key = AUTH0_NAMESPACE + 'isAdmin';
      if (!auth[key]) {
        history.push('/403-not-permission');
        return;
      }
      dispatch(setIsCheckPermission(true));
    } catch (e) {
      dispatch(setIsCheckPermission(true));
      // if (e.error === 'login_required') {
      //   toast.error(t('alert:loginRequired'));
      // } else if (e.error === 'invalid_grant') {
      //   toast.error(t('alert:sessionExpired'));
      //   setTimeout(() => {
      //     sec.getLogout({});
      //   }, 1000);
      // } else {
      //   console.error(e);
      // }
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  // Flag show Loading when check permission a first time
  if (!isCheckPermiss) {
    return <LoadingIndicator />;
  }

  return (
    <LayoutPage path={path}>
      <Route exact={exact} path={path} component={Component} />
    </LayoutPage>
  );
};

export default ProtectedRoute;
