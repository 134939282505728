/// /Adonis server
// export const API_URL = process.env.REACT_APP_API_URL;
// export const LANGUAGE_DISPLAY = process.env.REACT_APP_LANGUAGE_DISPLAY;

import { LAGUANGE_LIST } from './constants';

const userLocale =
  navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
const langDefault = userLocale && userLocale.split('-')[0];

// //Parse server
export const APP_ID = process.env.REACT_APP_APP_ID;
export const API_URL = process.env.REACT_APP_API_URL;
export const REST_KEY = process.env.REACT_APP_REST_KEY;
export const LANGUAGE_DISPLAY = LAGUANGE_LIST.includes(langDefault)
  ? langDefault
  : process.env.REACT_APP_LANGUAGE_DISPLAY;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || 'dev-rqs17ucm.us.auth0.com';
export const CALLBACK_URL = process.env.REACT_APP_AUTH0_CALLBACK_URL;
export const AUTH0_AUDIENCE =
  process.env.REACT_APP_AUTH0_AUDIENCE || 'https://dev-rqs17ucm.us.auth0.com/api/v2/';
export const AUTH0_CLIENT_ID =
  process.env.REACT_APP_AUTH0_CLIENT_ID || 'xmHHBVG44sYR0CP59AS5vysHMlAGa6Cp';
export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
export const AUTH0_NAMESPACE = process.env.REACT_APP_AUTH0_NAMESPACE;
export const SYSTEM_ADMIN_URL = process.env.REACT_APP_SYSTEM_ADMIN_EXTERNAL_API_URL;
