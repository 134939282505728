import { all } from 'redux-saga/effects';
import watchAuth from 'providers/AuthProvider/saga';
// PLOP: Import sagas below -- DO NOT ERASE
import watchProduct from 'providers/ProductProvider/saga';
import watchRole from 'providers/RoleProvider/saga';
import watchUser from 'providers/UserProvider/saga';

export default function* rootSaga(): Generator {
  yield all([
    watchAuth(),
    // PLOP: Use sagas below -- DO NOT ERASE
    watchProduct(),
    watchRole(),
    watchUser(),
  ]);
}
