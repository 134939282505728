import { AxiosResponse } from 'axios';
import { ISearch } from 'models/common';
import { ICreateRole, IEditRole } from 'models/role';
import apiInstance from '../utils/api';

export default class RoleService extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  getRoles = (params: ISearch): Promise<AxiosResponse> => {
    return this.get(`/${params.productId}/roles`, { params });
  };

  getDetail = (productId: string, id: string): Promise<AxiosResponse> => {
    return this.get(`/${productId}/roles/${id}`);
  };

  addRole = (payload: ICreateRole): Promise<AxiosResponse> => {
    return this.post(`/${payload.productId}/roles`, payload);
  };

  editRole = (payload: IEditRole): Promise<AxiosResponse> => {
    return this.patch(`/${payload.productId}/roles/${payload.id}`, payload);
  };

  deleteRole = (payload: IEditRole): Promise<AxiosResponse> => {
    return this.delete(`/${payload.productId}/roles/${payload.id}`);
  };
}
