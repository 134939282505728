import { useEffect, Suspense } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import { toast, ToastContainer } from 'react-toastify';
import en from 'antd/lib/locale/en_US';
import ja from 'antd/lib/locale/ja_JP';
import moment from 'moment';
import { ErrorBoundary } from 'admin-console-library';
import LoadingOverlay from 'react-loading-overlay';
import { useAuth0 } from '@auth0/auth0-react';
import { indexRoutes, PublicRoutes, ProtectedRoute } from 'routes';
import ConfirmModal from 'components/ConfirmModal';
import DashboardPage from 'containers/Layout';
import { useAppSelector } from 'store';
import 'styles/global.less';
import 'moment/locale/ja';
import { LAGUANGE_LIST } from 'utils/constants';
import Helper from 'utils/helpers';
LoadingOverlay.propTypes = undefined;

LAGUANGE_LIST.forEach((item) => {
  moment.locale(item, {
    week: {
      dow: 8,
    },
  });
  moment.locale(item);
});

const intlMap = {
  en,
  ja,
};
const App = (): JSX.Element => {
  const lang = useAppSelector((state) => state.lang);
  const loading = useAppSelector((state) => state.general.loading);
  const location = useLocation();
  const { search, pathname } = location;
  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');
  const errorDescription = params.get('error_description');
  const redirectUrl = Helper.getRedirectUrl();
  const { logout } = useAuth0();
  // sec.setAccessTokenSilently(getAccessTokenSilently({}));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  useEffect(() => {
    if (redirectUrl) {
      window.location.replace(Helper.convertUrl(redirectUrl));
      Helper.storeRedirectUrl('');
      return;
    }
    if (error) {
      toast.error(errorDescription);
      setTimeout(() => {
        if (Helper.getInviteUrl()) {
          logout({
            returnTo: Helper.getInviteUrl() || '/',
          });
          Helper.storeInviteUrl('');
        } else {
          logout({
            returnTo: window.location.origin,
          });
        }
      }, 2000);
    } else {
      Helper.storeInviteUrl('');
    }
  }, []);

  // if (isLoading) {
  //   return <LoadingIndicator />;
  // }

  // if (!isAuthenticated) {
  //   return <LoginPage />;
  // }

  return (
    <div className={`app-container ${loading ? 'loading' : ''}`}>
      <ConfirmModal />
      <ConfigProvider locale={intlMap[lang.current]} autoInsertSpaceInButton={false}>
        <ErrorBoundary>
          <Suspense
            fallback={
              <DashboardPage path="">
                <Spin size="large" className="position-center" />
              </DashboardPage>
            }
          >
            <Switch>
              {indexRoutes.map((route) => {
                if (!route.requireLogin) {
                  return (
                    <PublicRoutes
                      exact={route.exact}
                      path={route.path}
                      component={route.component}
                      key={route.path}
                    />
                  );
                }
                return (
                  <ProtectedRoute
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    key={route.path}
                  />
                );
              })}
            </Switch>
          </Suspense>
        </ErrorBoundary>
        <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar />
        <LoadingOverlay active={loading} spinner={<Spin size="large" />} />
      </ConfigProvider>
    </div>
  );
};

export default App;
