import { put, takeEvery, takeLeading, delay } from 'redux-saga/effects';
import _pick from 'lodash/pick';
import { push } from 'connected-react-router';
import { AnyAction } from 'redux';
import Helper from 'utils/helpers';
import { callApi } from 'providers/GeneralProvider/saga';
import {
  loginRequest,
  loginSuccess,
  loginError,
  logoutRequest,
  logoutError,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserError,
  changePasswordRequest,
  changePasswordSuccess,
} from 'providers/AuthProvider/slice';
import { userService } from 'services';

function* handleLogin(action) {
  try {
    // NOTE: Replace with your api and configuration

    // const result = yield callApi(
    //   api.post,
    //   '/api/v1/admin/login',
    //   action.payload,
    // );
    // const { data } = result;
    // const token = _get(data, 'token', '');

    // if (!token) {
    //   yield put(loginError());
    //   return;
    // }
    yield put(loginSuccess(null));
    Helper.storeAuthToken('your_token');
    yield put(push('/'));
  } catch (error) {
    yield put(loginError());
  }
}

export function* handleLogout(): Generator {
  try {
    // Need to call api logout to delete sessionToken on server without waiting until it finishes
    // use Call of redux-saga effect, not callApi
    // call(api.put, '/account/admin/logout');
    Helper.removeAuthToken();
    window.location.reload();
  } catch (error) {
    yield put(logoutError());
  }
}

export function* getCurrentUser(): Generator {
  try {
    // TODO: Replace delay with api call and getCurrentUserSuccess with result
    yield delay(3000);
    // const response = yield callApi(api.get, '/account/admin/');
    yield put(getCurrentUserSuccess(null));
  } catch (error) {
    yield put(getCurrentUserError());
  }
}

export function* changePassword(action: AnyAction): Generator {
  try {
    const params = _pick(action.payload, ['client_id', 'email', 'connection']);
    const data = yield callApi(userService.changePassword, params);

    yield put(changePasswordSuccess({}));
    const message = `${data}`;
    Helper.toastr('', message, 'success');
  } catch (error) {}
}

export default function* watchAuth(): Generator {
  yield takeLeading(loginRequest.type, handleLogin);
  yield takeLeading(logoutRequest.type, handleLogout);
  yield takeEvery(getCurrentUserRequest.type, getCurrentUser);
  yield takeLeading(changePasswordRequest.type, changePassword);
}
