import { AxiosResponse } from 'axios';
import { ISearch } from 'models/common';
import {
  IAddUser,
  IArchiveUser,
  IAssignRole,
  IDeleteUser,
  IEditUser,
  IInviteUser,
  IRegisterUser,
} from 'models/user';
import { AUTH0_DOMAIN } from 'utils/config';
import apiInstance from '../utils/api';

export default class UserService extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  getUsers = (params: ISearch): Promise<AxiosResponse> => {
    return this.get('', { params });
  };

  getDetail = (id: string): Promise<AxiosResponse> => {
    return this.get(`/${id}`);
  };

  getUnAssignRoleByUser = (id: string, productId: string): Promise<AxiosResponse> => {
    return this.get(`/${id}/product/${productId}/unassigned-roles`);
  };

  changePassword = (payload: unknown): Promise<AxiosResponse> => {
    return this.post(`https://${AUTH0_DOMAIN}/dbconnections/change_password`, payload);
  };

  inviteUser = (payload: IInviteUser): Promise<AxiosResponse> => {
    return this.post(`/invitation`, payload);
  };

  addUser = (payload: IAddUser): Promise<AxiosResponse> => {
    return this.post(`/create-user`, payload);
  };

  verifyTokenInviteUser = (payload: { token: string }): Promise<AxiosResponse> => {
    const config = { unauthenticated: true };
    return this.post(`/invitation/verify-token`, payload, { ...config });
  };

  registerUser = (payload: IRegisterUser): Promise<AxiosResponse> => {
    return this.post(`/invitation/registration`, payload);
  };

  editUser = (payload: IEditUser): Promise<AxiosResponse> => {
    return this.patch(`/${payload.id}`, payload);
  };

  deleteUser = (payload: IDeleteUser): Promise<AxiosResponse> => {
    return this.delete(`/${payload.id}`);
  };

  archiveUser = (payload: IArchiveUser): Promise<AxiosResponse> => {
    return this.patch(`/${payload.id}/archive`, null);
  };

  unArchiveUser = (payload: IArchiveUser): Promise<AxiosResponse> => {
    return this.patch(`/${payload.id}/unarchive`, null);
  };

  assignRole = (payload: IAssignRole): Promise<AxiosResponse> => {
    return this.post(`/${payload.id}/assign-roles`, payload.data);
  };

  unAssignRole = (payload: IAssignRole): Promise<AxiosResponse> => {
    return this.post(`/${payload.id}/unassign-roles`, payload.data);
  };
}
