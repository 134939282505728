import { combineReducers, Reducer } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import lang from 'providers/LangProvider/slice';
import auth from 'providers/AuthProvider/slice';
import general from 'providers/GeneralProvider/slice';
// PLOP: Import reducers below -- DO NOT ERASE
import product from 'providers/ProductProvider/slice';
import role from 'providers/RoleProvider/slice';
import user from 'providers/UserProvider/slice';

const createRootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    lang,
    auth,
    general,
    // PLOP: Use reducers below -- DO NOT ERASE
    product,
    role,
    user,
  });
export default createRootReducer;
