import FlagEN from 'assets/images/gb.svg';
import FlagJP from 'assets/images/ja.svg';

export const INSTALLATION_ID = '@skeleton_installationId';

export const REG_EXP = {
  PASSWORD_POLICY:
    // eslint-disable-next-line no-useless-escape
    /^(?=.*[a-z])(?=.*[A-Z])[A-Za-z\d!\"#$%&'()*+,-./:;<=>?@^_`{|}~\[\]]{8,}$/,
};
export enum Limit {
  MAX_LIMIT = 10000,
  AGENT_LIST = 10,
}
export enum UserStatus {
  ACTIVE = 'ACTIBE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}
export const ERRORS = {
  INVALID_SESSION_TOKEN: {
    code: 209,
    message: 'Invalid session token!',
  },
  EXISTED_EMAIL: {
    code: 202,
    message: 'Account already exists for this username.',
  },
};

export enum MenuHeader {
  LOGOUT = 'LOGOUT',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export enum MenuHeaderLabel {
  LOGOUT = 'Log out',
  CHANGE_PASSWORD = 'Change password',
}

export const GET_ACCESS_TOKEN_MINUTE_MS = 36000;
export const BADGE_COLOR = {
  ACCOUNT_STATUS: {
    active: {
      text: 'status:active',
      color: 'green',
    },
    pending: {
      text: 'status:pending',
      color: 'yellow',
    },
    disabled: {
      text: 'status:disabled',
      color: 'gray',
    },
  },
  PAYOUT_STATUS: {
    COMPLETED: {
      text: 'COMPLETED',
      color: 'cyan',
    },
    UNCOMPLETE: {
      text: 'UNCOMPLETE',
      color: 'volcano',
    },
  },
};
export enum MomentFormat {
  MONTH_YEAR_SHORT = 'MM/YY',
  YEAR_MONTH_DATE = 'YYYY/MM/DD',
  YEAR_MONTH = 'YYYY/MM',
  YEAR_MONTH_DASH = 'YYYY-MM',
  YEAR_MONTH_DATE_HOUR = 'YYYY/MM/DD HH:mm',
  YEAR_MONTH_DATE_HOUR_DASH = 'YYYY-MM-DD HH:mm',
  HOUR_YEAR_MONTH_DATE = 'HH:mm YYYY-MM-DD',
  HOUR_YEAR_MONTH_DATE_JP = 'HH:mm YYYY年MM月DD日',
}

export enum StatusColumnText {
  pending = 'Pending',
  active = 'Active',
  activated = 'Active',
  archived = 'Archived',
  disabled = 'Disabled',
  cancelled = 'Cancelled',
  confirmed = 'Confirmed',
  complete = 'Complete',
}

export enum StatusEnum {
  pending = 'pending',
  active = 'active',
  activated = 'activated',
  archived = 'archived',
  disabled = 'disabled',
  cancelled = 'cancelled',
  confirmed = 'confirmed',
  complete = 'complete',
}

export const BOOKING_STATUS = ['pending', 'confirmed', 'cancelled', 'complete', 'deleted'];

export const REGION_ENUM = {
  北海道: '北海道',
  東北: '東北',
  関東: '関東',
  中部: '中部',
  関西: '関西',
  中国: '中国',
  四国地方: '四国地方',
  九州: '九州',
};

export const ADDRESS_PRIVACY_ENUM = {
  Public: 'Public',
  Protect: 'Protect',
};

export const ACCOUNT_TYPE_ENUM = {
  Saving: 'Saving',
  Standard: 'Standard',
};

export const TRANSLATION = {
  male: 'profile:male',
  female: 'profile:female',
  unanswered: 'profile:unanswered',
  protect: 'type:protect',
  public: 'type:public',
  saving: 'type:saving',
  standard: 'type:standard',
};

export const viewDatePickerOptions = [
  { label: 'day', value: 'DAY' },
  { label: 'week', value: 'WEEK' },
  { label: 'month', value: 'MONTH' },
  { label: 'payoutCycle', value: 'PAYOUT_CYCLE' },
  { label: 'custom', value: 'CUSTOM' },
];

export const VIEW_MODE = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  PAYOUT_CYCLE: 'PAYOUT_CYCLE',
  CUSTOM: 'CUSTOM',
};

export const LAGUANGE_LIST = ['en', 'ja'];

export const statusClassName = {
  activated: 'active-status',
  archived: 'disabled-status',
};

export const langOptions = [
  {
    label: '日本語',
    value: 'ja',
    image: FlagJP,
  },
  {
    label: 'English',
    value: 'en',
    image: FlagEN,
  },
];
