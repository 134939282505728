let getAccessTokenSilently;
let getLoginWithRedirect;
let getLogout;

const sec = {
  getAccessTokenSilently: (option): void => getAccessTokenSilently(option),
  setAccessTokenSilently: (func): void => (getAccessTokenSilently = func),
  getLoginWithRedirect: (option): void => getLoginWithRedirect(option),
  setLoginWithRedirect: (func): void => (getLoginWithRedirect = func),
  getLogout: (option): void => getLogout(option),
  setLogout: (func): void => (getLogout = func),
};

export default sec;
