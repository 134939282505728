import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { goBack, replace } from 'connected-react-router';
import { toast } from 'react-toastify';
import { getDetail } from 'utils/mock-server';
import data from 'data/role-data.json';
import Loading from 'utils/loading';
import { callApi } from 'providers/GeneralProvider/saga';
import i18n from 'i18n';
import { roleService } from 'services';
import Helpers from 'utils/helpers';
import { ISearch } from 'models/common';
import {
  // PLOP: Add slice import below -- DO NOT ERASE
  getRoleDetailRequest,
  getRoleDetailSuccess,
  getRoleDetailFailure,
  getRoleListRequest,
  getRoleListSuccess,
  getRoleListFailure,
  createRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  editRoleRequest,
  editRoleSuccess,
  editRoleFailure,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleFailure,
  deactivateRoleRequest,
  deactivateRoleSuccess,
  deactivateRoleFailure,
  reset,
} from './slice';

// PLOP: Add worker export below -- DO NOT ERASE
export function* getRoleDetail(action: AnyAction): unknown {
  try {
    yield put(reset({}));
    Loading.show();
    const res = yield callApi(roleService.getDetail, action.payload.parentId, action.payload.id);
    Loading.hide();

    yield put(getRoleDetailSuccess({ detail: res.data }));
  } catch (error) {
    Loading.hide();
    yield put(getRoleDetailFailure({ error }));
  }
}
export function* getRoleList(action: AnyAction): unknown {
  try {
    const params: ISearch = Helpers.convertParams(action.payload);
    if (!params.productId) {
      yield put(getRoleListSuccess({ list: [], total: 0 }));
      return;
    }
    const res = yield callApi(roleService.getRoles, params);
    yield put(getRoleListSuccess({ list: res.data, total: res.pagination.total }));
  } catch (error) {
    yield put(getRoleListFailure({ error }));
  }
}

export function* createRole(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(roleService.addRole, action.payload);
    Loading.hide();

    if (res) {
      toast.success(i18n.t('message:created'));
      yield put(createRoleSuccess(res));
      // yield put(replace(`/roles?productId=${action.payload.productId}`));
      yield put(replace(`/roles/${res.data}?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(createRoleFailure({ error }));
  }
}

export function* deactivateRole(action: AnyAction): unknown {
  try {
    const res = yield getDetail(data, action.payload.id);
    yield put(deactivateRoleSuccess(res));
  } catch (error) {
    yield put(deactivateRoleFailure({ error }));
  }
}

export function* deleteRole(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(roleService.deleteRole, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:deleted'));
      yield put(deleteRoleSuccess(res));
      yield put(replace(`/roles?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(deleteRoleFailure({ error }));
  }
}

export function* editRole(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(roleService.editRole, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:updated'));
      yield put(editRoleSuccess(res));
      yield put(goBack());
      // yield put(replace(`/roles?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(editRoleFailure({ error }));
  }
}

export default function* roleWatch(): Generator {
  // PLOP: Add fork below -- DO NOT ERASE
  yield takeEvery(getRoleDetailRequest.type, getRoleDetail);
  yield takeEvery(getRoleListRequest.type, getRoleList);
  yield takeLeading(createRoleRequest.type, createRole);
  yield takeLeading(editRoleRequest.type, editRole);
  yield takeLeading(deleteRoleRequest.type, deleteRole);
  yield takeLeading(deactivateRoleRequest.type, deactivateRole);
}
