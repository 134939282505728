import { AxiosResponse } from 'axios';
import apiInstance from '../utils/api';

export default class FileService extends apiInstance {
  constructor(protected apiUrl: string) {
    super(apiUrl);
  }

  upload = (payload: unknown): Promise<AxiosResponse> => {
    return this.post('/upload/uploadImage', payload);
  };
}
