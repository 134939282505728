import { put, takeEvery, takeLeading } from 'redux-saga/effects';
import { goBack, replace } from 'connected-react-router';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import { userService } from 'services';
import data from 'data/user-data.json';
import { getDetail } from 'utils/mock-server';
import Loading from 'utils/loading';
import { callApi } from 'providers/GeneralProvider/saga';
import Helpers from 'utils/helpers';
import i18n from 'i18n';
import { ISearch } from 'models/common';
import { getProductListByUserRequest } from 'providers/ProductProvider/slice';
import {
  // PLOP: Add slice import below -- DO NOT ERASE
  getUserDetailRequest,
  getUserDetailSuccess,
  getUserDetailFailure,
  getUserListRequest,
  getUserListSuccess,
  getUserListFailure,
  inviteUserRequest,
  inviteUserSuccess,
  inviteUserFailure,
  editUserRequest,
  editUserSuccess,
  editUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
  archiveUserRequest,
  archiveUserSuccess,
  archiveUserFailure,
  activeUserRequest,
  activeUserSuccess,
  activeUserFailure,
  changePasswordUserRequest,
  changePasswordUserSuccess,
  changePasswordUserFailure,
  verifyTokenInviteUserRequest,
  verifyTokenInviteUserSuccess,
  verifyTokenInviteUserFailure,
  registerUserRequest,
  registerUserSuccess,
  registerUserFailure,
  getUnAssignRoleByUserRequest,
  getUnAssignRoleByUserSuccess,
  getUnAssignRoleByUserFailure,
  assignRoleRequest,
  assignRoleSuccess,
  assignRoleFailure,
  unAssignRoleRequest,
  unAssignRoleSuccess,
  unAssignRoleFailure,
  reset,
  addUserRequest,
  addUserSuccess,
  addUserFailure,
} from './slice';

// PLOP: Add worker export below -- DO NOT ERASE
export function* getUserDetail(action: AnyAction): unknown {
  try {
    yield put(reset({}));
    Loading.show();
    const res = yield callApi(userService.getDetail, action.payload.id);
    Loading.hide();

    yield put(getUserDetailSuccess({ detail: res.data }));
  } catch (error) {
    Loading.hide();
    yield put(getUserDetailFailure({ error }));
  }
}

export function* changePasswordUser(action: AnyAction): unknown {
  try {
    const res = yield getDetail(data, action.payload.id);
    yield put(changePasswordUserSuccess(res));
  } catch (error) {
    yield put(changePasswordUserFailure({ error }));
  }
}

export function* archiveUser(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.archiveUser, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:archived'));
      yield put(archiveUserSuccess(res));
      yield put(getUserDetailRequest(action.payload));
      // yield put(replace(`/users?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(archiveUserFailure({ error }));
  }
}

export function* activeUser(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.unArchiveUser, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:actived'));
      yield put(activeUserSuccess(res));
      yield put(getUserDetailRequest(action.payload));
      // yield put(replace(`/users?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(activeUserFailure({ error }));
  }
}

export function* assignRole(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.assignRole, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:assigned'));
      yield put(assignRoleSuccess(res));
      yield put(getProductListByUserRequest(action.payload.id));
      // yield put(replace(`/users?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(assignRoleFailure({ error }));
  }
}

export function* unAssignRole(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.unAssignRole, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:unAssigned'));
      yield put(unAssignRoleSuccess(res));
      yield put(getProductListByUserRequest(action.payload.id));
      // yield put(replace(`/users?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(unAssignRoleFailure({ error }));
  }
}

export function* deleteUser(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.deleteUser, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:deleted'));
      yield put(deleteUserSuccess(res));
      yield put(replace(`/users?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(deleteUserFailure({ error }));
  }
}

export function* editUser(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.editUser, action.payload);
    Loading.hide();
    if (res) {
      toast.success(i18n.t('message:updated'));
      yield put(editUserSuccess(res));
      yield put(goBack());
      // yield put(replace(`/users?productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(editUserFailure({ error }));
  }
}

export function* inviteUser(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.inviteUser, action.payload);
    Loading.hide();

    if (res) {
      toast.success(i18n.t('message:invited'));
      yield put(inviteUserSuccess(res));
      yield put(replace(`/users??productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(inviteUserFailure({ error }));
  }
}

export function* verifyTokenInviteUser(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.verifyTokenInviteUser, action.payload);
    Loading.hide();

    if (res) {
      toast.success(i18n.t('message:verify'));
      yield put(verifyTokenInviteUserSuccess(res));
    }
  } catch (error) {
    Loading.hide();
    yield put(verifyTokenInviteUserFailure({ error }));
  }
}

export function* registerUser(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.registerUser, action.payload);
    Loading.hide();

    if (res) {
      toast.success(i18n.t('message:registerSuccess'));
      yield put(registerUserSuccess(res));
    }
  } catch (error) {
    Loading.hide();
    yield put(registerUserFailure({ error }));
  }
}

export function* getUserList(action: AnyAction): unknown {
  try {
    const params: ISearch = Helpers.convertParams(action.payload);
    // if (!params.productId) return;
    if (!params.productId) {
      yield put(getUserListSuccess({ list: [], total: 0 }));
      return;
    }
    const res = yield callApi(userService.getUsers, params);

    yield put(getUserListSuccess({ list: res.data, total: res.pagination.total }));
  } catch (error) {
    yield put(getUserListFailure({ error }));
  }
}

export function* getUnAssignRoleByUser(action: AnyAction): unknown {
  try {
    const res = yield callApi(
      userService.getUnAssignRoleByUser,
      action.payload.id,
      action.payload.productId,
    );

    yield put(
      getUnAssignRoleByUserSuccess({
        listUnAssignRoleByUser: res.data,
      }),
    );
  } catch (error) {
    yield put(getUnAssignRoleByUserFailure({ error }));
  }
}

export function* addUser(action: AnyAction): unknown {
  try {
    Loading.show();
    const res = yield callApi(userService.addUser, action.payload);
    Loading.hide();

    if (res) {
      toast.success(i18n.t('message:created'));
      yield put(addUserSuccess(res));
      yield put(replace(`/users??productId=${action.payload.productId}`));
    }
  } catch (error) {
    Loading.hide();
    yield put(addUserFailure({ error }));
  }
}

export default function* userWatch(): Generator {
  // PLOP: Add fork below -- DO NOT ERASE
  yield takeEvery(getUserListRequest.type, getUserList);
  yield takeEvery(getUnAssignRoleByUserRequest.type, getUnAssignRoleByUser);
  yield takeLeading(inviteUserRequest.type, inviteUser);
  yield takeLeading(editUserRequest.type, editUser);
  yield takeLeading(deleteUserRequest.type, deleteUser);
  yield takeLeading(archiveUserRequest.type, archiveUser);
  yield takeLeading(activeUserRequest.type, activeUser);
  yield takeLeading(assignRoleRequest.type, assignRole);
  yield takeLeading(unAssignRoleRequest.type, unAssignRole);
  yield takeLeading(changePasswordUserRequest.type, changePasswordUser);
  yield takeLeading(getUserDetailRequest.type, getUserDetail);
  yield takeLeading(verifyTokenInviteUserRequest.type, verifyTokenInviteUser);
  yield takeLeading(registerUserRequest.type, registerUser);
  yield takeLeading(addUserRequest.type, addUser);
}
