import { toast } from 'react-toastify';
/* eslint-disable prefer-promise-reject-errors */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import i18n from 'i18n';
import { API_URL, LANGUAGE_DISPLAY } from './config';
import Helper from './helpers';
import sec from './security';
// import Helper from './helpers';

const api = axios.create({
  baseURL: API_URL,
});

// api.interceptors.request.use((config: AxiosRequestConfig) => {
//   const authToken = _get(config, 'token');
//   console.log('configconfigconfig', config);
//   const requestConfig = {
//     ...config,
//     // Parse server
//     // 'X-Parse-Application-Id': APP_ID,
//     // 'X-Parse-REST-API-Key': REST_KEY,
//     // ...(authToken ? { 'X-Parse-Session-Token': authToken } : {}),
//   };
//   // Adonis
//   if (authToken) {
//     requestConfig.headers.Authorization = `Bearer ${authToken}`;
//   }
//   requestConfig.headers['Accept-Language'] = LANGUAGE_DISPLAY;
//   return requestConfig;
// });

interface AxiosRequestConfigExtra extends AxiosRequestConfig {
  unauthenticated?: boolean;
}

api.interceptors.request.use(
  async (config: AxiosRequestConfigExtra) => {
    if (!config.unauthenticated) {
      try {
        const token = await sec.getAccessTokenSilently({});
        config.headers.authorization = `Bearer ${token}`;
      } catch (e) {
        if (e.error === 'login_required') {
          toast.error(i18n.t('alert:loginRequired'));
          sec.getLoginWithRedirect({
            appState: {
              returnTo: `${window.location.pathname}${window.location.search}`,
            },
          });
        } else if (e.error === 'invalid_grant') {
          const CancelToken = axios.CancelToken;
          setTimeout(() => {
            sec.getLogout({});
          }, 1000);
          return {
            ...config,
            cancelToken: new CancelToken((cancel) => cancel(i18n.t('alert:sessionExpired'))),
          };
        } else {
          console.error(e);
        }
      }
    }
    const language = Helper.getLang() || LANGUAGE_DISPLAY;
    config.headers['Accept-Language'] = language;
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response.data,
  ({ message, response }) => {
    if (response?.data) {
      return Promise.reject({
        message: response.data.error || response.data.message || message,
        code: response.data.code ?? -1,
      });
    }
    return Promise.reject({ message, code: response?.status ?? -1 });
  },
);

class apiInstance {
  constructor(protected readonly apiUrl: string) {}

  get(url: string, config?: AxiosRequestConfigExtra): Promise<AxiosResponse> {
    return api.get(`${Helper.getUrl(url, this.apiUrl)}`, config).then((response) => response);
  }

  post(url: string, payload: unknown, config?: AxiosRequestConfigExtra): Promise<AxiosResponse> {
    return api
      .post(`${Helper.getUrl(url, this.apiUrl)}`, payload, config)
      .then((response) => response);
  }

  put(url: string, payload: unknown, config?: AxiosRequestConfigExtra): Promise<AxiosResponse> {
    return api
      .put(`${Helper.getUrl(url, this.apiUrl)}`, payload, config)
      .then((response) => response);
  }

  patch(url: string, payload: unknown, config?: AxiosRequestConfigExtra): Promise<AxiosResponse> {
    return api
      .patch(`${Helper.getUrl(url, this.apiUrl)}`, payload, config)
      .then((response) => response);
  }

  delete(url: string, config?: AxiosRequestConfigExtra): Promise<AxiosResponse> {
    return api.delete(`${Helper.getUrl(url, this.apiUrl)}`, config).then((response) => response);
  }
}

export default apiInstance;
