import { Button, Col, Dropdown, Menu, Row, Space, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
// import LogoGolFull from '../../../public/';
import { DownOutlined } from '@ant-design/icons';
import Helper from 'utils/helpers';
import './styles.less';
import { useAppDispatch, useAppSelector } from 'store';
import i18n from 'i18n';
import { changeLanguage } from 'providers/LangProvider/slice';
import { langOptions } from 'utils/constants';

const LoginPage = (): JSX.Element => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation('login');
  const lang = useAppSelector((state) => state.lang);
  const dispatch = useAppDispatch();

  const handleChangeLanguage = (langValue) => {
    i18n.changeLanguage(langValue);
    dispatch(changeLanguage({ current: langValue }));
  };
  const getLanguageImage = (langValue: string) => {
    const langItem = langOptions.find((lang) => lang.value === langValue);
    return langItem ? langItem.image : '';
  };
  const menu = (
    <Menu onClick={(e) => handleChangeLanguage(e.key)}>
      {langOptions.map((item) => (
        <Menu.Item key={item.value}>
          <Space>
            <Image width={20} preview={false} src={item.image} /> <span>{item.label}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleLogin = () => {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
      redirectUri: `${window.location.origin}`,
    });
    Helper.removeChangePassowrd();
  };

  return (
    <div className="login-page-wrapper">
      <Dropdown overlay={menu} trigger={['click']} className="box-language">
        <Space className="pointer">
          <Image width={20} preview={false} src={getLanguageImage(lang.current)} />
          <DownOutlined style={{ fontSize: '10px', color: '#000' }} />
        </Space>
      </Dropdown>
      <div className="login-form-container">
        <Row className="logo-wrapper" justify="center" align="middle">
          <Col>
            <img height={80} width={80} className="logo" src={'/c2c-logo.png'} alt="logo" />
          </Col>
          <Col>
            <h1 className="logo-title">Admin System</h1>
          </Col>
        </Row>
        <div className="login-form">
          <Button size="large" className="login-btn" onClick={handleLogin}>
            {t('login')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
