import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { AppstoreOutlined, SolutionOutlined, TeamOutlined } from '@ant-design/icons';
import { Route } from './index';

const Role = lazy(() => import(/* webpackChunkName: "Role" */ 'containers/Roles'));
const User = lazy(() => import(/* webpackChunkName: "User" */ 'containers/Users'));
const CreateUserPage = lazy(
  () => import(/* webpackChunkName: "CreateUserPage" */ 'containers/Users/Create'),
);
const UserDetail = lazy(
  () => import(/* webpackChunkName: "RoleDetail" */ 'containers/Users/Detail'),
);
const InviteUserPage = lazy(
  () => import(/* webpackChunkName: "CreateUserPage" */ 'containers/Users/Invite'),
);
const EditUserPage = lazy(
  () => import(/* webpackChunkName: "EditUserPage" */ 'containers/Users/Edit'),
);
const CreateRolePage = lazy(
  () => import(/* webpackChunkName: "CreateRolePage" */ 'containers/Roles/Create'),
);
const EditRolePage = lazy(
  () => import(/* webpackChunkName: "EditRolePage" */ 'containers/Roles/Edit'),
);
const NotFoundPage = lazy(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'containers/NotFoundPage'),
);
const NotPermissionPage = lazy(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'containers/NotPermissionPage'),
);
const InvitationUserPage = lazy(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'containers/InvitationUserPage'),
);
const RegisterPage = lazy(
  () => import(/* webpackChunkName: "NotFoundPage" */ 'containers/RegisterPage'),
);
// PLOP: Add pages import below -- DO NOT ERASE
const RoleDetail = lazy(
  () => import(/* webpackChunkName: "RoleDetail" */ 'containers/Roles/Detail'),
);
const ProductDetail = lazy(
  () => import(/* webpackChunkName: "ProductDetail" */ 'containers/Product/Detail'),
);
const ProductList = lazy(
  () => import(/* webpackChunkName: "ProductList" */ 'containers/Product/List'),
);
const EditProductPage = lazy(
  () => import(/* webpackChunkName: "EditUserPage" */ 'containers/Product/Edit'),
);
const CreateProductPage = lazy(
  () => import(/* webpackChunkName: "CreateRolePage" */ 'containers/Product/Create'),
);

const NotFoundRedirect = (): JSX.Element => <Redirect to="/404-not-found" />;

const RedirectToHome = (): JSX.Element => <Redirect to="/products" />;

const indexRoutes: Route[] = [
  { path: '/404-not-found', component: NotFoundPage, exact: true },
  { path: '/403-not-permission', component: NotPermissionPage, exact: true },
  { path: '/user/invitation', component: InvitationUserPage, exact: true },
  { path: '/register', component: RegisterPage, exact: true },
  {
    path: '/products',
    component: ProductList,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:products',
    icon: <AppstoreOutlined />,
  },
  {
    path: '/products/create',
    component: CreateProductPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/products/:id/edit',
    component: EditProductPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/products/:id',
    component: ProductDetail,
    requireLogin: true,
    exact: false,
  },
  {
    path: '/roles',
    component: Role,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:roles',
    icon: <SolutionOutlined />,
  },
  {
    path: '/users',
    component: User,
    requireLogin: true,
    exact: true,
    menu: true,
    name: 'menuBar:users',
    icon: <TeamOutlined />,
  },
  {
    path: '/users/create',
    component: CreateUserPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/users/invite',
    component: InviteUserPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/users/:id/edit',
    component: EditUserPage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/users/:id',
    component: UserDetail,
    requireLogin: true,
    exact: false,
  },
  {
    path: '/roles/create',
    component: CreateRolePage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/roles/:id/edit',
    component: EditRolePage,
    requireLogin: true,
    exact: true,
  },
  {
    path: '/roles/:id',
    component: RoleDetail,
    requireLogin: true,
    exact: false,
  },
  {
    path: '/',
    component: RedirectToHome,
    requireLogin: true,
    exact: true,
  },
  { path: '*', component: NotFoundRedirect },
];

export default indexRoutes;
