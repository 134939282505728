import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ISearch } from 'models/common';
import { fileService } from 'services';
// import {api} from 'utils/api';
import { INSTALLATION_ID, statusClassName, StatusColumnText } from './constants';

const Helper = {
  generateUUID: (): string => {
    const hexOctet = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    return `${
      hexOctet + hexOctet
    }-${hexOctet}-${hexOctet}-${hexOctet}-${hexOctet}${hexOctet}${hexOctet}`;
  },
  // getAuthToken: (): string | null => localStorage.getItem('op_token'),
  // storeAuthToken: (token): void => localStorage.setItem('op_token', token),
  removeAuthToken: (): void => localStorage.removeItem('op_token'),
  setChangePassowrd: (): void => localStorage.setItem('change_password', 'change_password'),
  getChangePassowrd: (): string | null => localStorage.getItem('change_password'),
  removeChangePassowrd: (): void => localStorage.removeItem('change_password'),
  getInstallationId: (): string => {
    let installationId;
    const storedInstallationId = Helper.getDataStored(INSTALLATION_ID);
    if (storedInstallationId) {
      installationId = storedInstallationId;
    } else {
      installationId = Helper.generateUUID();
      Helper.storeData(INSTALLATION_ID, installationId);
    }
    return installationId;
  },
  uploadFile: async (file: {
    type: string;
    file: Blob;
  }): Promise<Record<string, unknown> | AxiosResponse | null> => {
    if (file) {
      const payload = new FormData();
      payload.append('type', file.type);
      payload.append('file', file.file);

      try {
        // return await api.post('/upload/uploadImage', payload);
        return await fileService.upload(payload);
      } catch (e) {
        return null;
      }
    }
    return null;
  },
  toastr: (description: string, message: string, type = 'success'): void => {
    if (type === 'success') {
      toast.success(message);
    } else if (type === 'error') {
      toast.error(message, { toastId: description });
    }
  },
  arrayToString: (array = []): string => array.map((item) => item).join(', '),
  getDataStored: (value: string): string | null => localStorage.getItem(value),
  storeData: (key: string, value: string): void => {
    localStorage.setItem(key, value);
  },
  getDownLoadFileCSV: (
    csvContent: number | boolean | BlobPart,
    csvFileName = 'dataList.csv',
  ): void => {
    const exportedFilename = csvFileName;
    const BOM = '\uFEFF';
    csvContent = BOM + csvContent;
    const blob = new Blob([csvContent], {
      type: `text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csvContent)}`,
    });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  formatCurrencyJPYName: (value: number | bigint): string => {
    const currencyJPYName = new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'name',
    });
    return currencyJPYName.format(value);
  },
  getFirstAndLastDayOfWeek: (date: string | number | Date): Record<string, number> => {
    const searchTime = new Date(date);
    // First day is the day of the month - the day of the week
    const firstDay = searchTime.getDate() - searchTime.getDay();
    const lastDay = firstDay + 6;
    return { firstDay, lastDay };
  },
  getFirstAndLastDayOfMonth: (date: Date): Record<string, Date> => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1); // get first day of the month
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); // get last day of the search month
    return { firstDay, lastDay };
  },
  getThreeDayBeforeCurrentDay: (
    date: { getFullYear: () => number; getMonth: () => number; getDate: () => number },
    index: number,
  ): Record<string, number> => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1); // get first day of the month
    const lastDayOfPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0);
    const firstDayOfMonth = firstDay.getDate();
    const datePast = date.getDate() - (3 - index); // get 3 days before current day
    const dateDisplayed =
      datePast < firstDayOfMonth ? lastDayOfPrevMonth.getDate() + datePast : datePast;
    return { datePast, dateDisplayed };
  },
  getThreeDayAfterCurrentDay: (
    date: { getFullYear: () => number; getMonth: () => number; getDate: () => number },
    index: number,
  ): Record<string, number> => {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0); // get last day of the search month
    const lastDayOfMonth = lastDay.getDate();
    const dateFuture = date.getDate() + (1 + index); // get 3 days after current day
    const dateDisplayed = dateFuture > lastDayOfMonth ? dateFuture - lastDayOfMonth : dateFuture;
    return { dateFuture, dateDisplayed };
  },
  getPrevCyclePayout: (
    searchTime: string,
    payoutCycleList: Record<string, string>[],
  ): Record<string, string> => {
    const CurrentDateNumber = (element) => element.value === searchTime;
    const index = payoutCycleList.findIndex(CurrentDateNumber);
    const prevIndex = index === 0 ? payoutCycleList.length - 1 : index - 1;
    const prevCycle = payoutCycleList[prevIndex].value;
    return { prevCycle };
  },
  getNextCyclePayout: (
    searchTime: string,
    payoutCycleList: Record<string, string>[],
  ): Record<string, string> => {
    const CurrentDateNumber = (element) => element.value === searchTime;
    const index = payoutCycleList.findIndex(CurrentDateNumber);
    const nextIndex = index === payoutCycleList.length - 1 ? 0 : index + 1;
    const nextCycle = payoutCycleList[nextIndex].value;
    return { nextCycle };
  },
  getUrl(url: string, apiUrl: string): string {
    return (url.startsWith('http') ? '' : apiUrl) + url;
  },
  convertUrl(url: string): string {
    let urlReturn;
    if (url.startsWith('http://') || url.startsWith('https://')) {
      urlReturn = url;
    } else {
      urlReturn = 'http://' + url;
    }
    return urlReturn;
  },
  convertPermission(permissions: string[]): Array<Record<string, string>> {
    if (!permissions || !permissions.length) return [];
    const list: Array<Record<string, any>> = [];
    permissions.forEach((permission) => {
      const arr = permission.split(':');
      const ob: Record<string, string> = {};
      if (arr.length > 1) {
        ob.name = arr[1];
        ob.roles = arr[0];
      } else {
        ob.name = 'invalid';
        ob.roles = arr[0];
      }
      list.push(ob);
    });
    const groupValues = list.reduce(function (obj, item) {
      obj[item.name] = obj[item.name] || [];
      obj[item.name].push(item.roles);
      return obj;
    }, {});

    const groups = Object.entries(groupValues).map(([name, roles], index) => ({
      name,
      roles,
      key: index.toString(),
    }));
    return groups;
  },
  countPermission(permissions: string[]): number {
    if (!permissions || !permissions.length) return 0;
    return permissions.length;
  },
  convertCode(value: string): string {
    if (!value) return '';
    const code = value.replaceAll(' ', '_').toLowerCase();
    return code;
  },
  getAuthToken: (): string | null => {
    return localStorage.getItem('access_token');
  },

  storeAuthToken: (token: string): void => {
    return localStorage.setItem('access_token', token);
  },

  getRefreshToken: (): string | null => {
    return localStorage.getItem('refresh_token');
  },

  storeRefreshToken: (refreshToken: string): void => {
    return localStorage.setItem('refresh_token', refreshToken);
  },

  getLang: (): string | null => {
    return localStorage.getItem('i18nextLng');
  },

  storeLang: (language: string): void => {
    return localStorage.setItem('language', language);
  },

  getInviteUrl: (): string | null => {
    return localStorage.getItem('inviteUrl');
  },

  storeInviteUrl: (inviteUrl: string): void => {
    return localStorage.setItem('inviteUrl', inviteUrl);
  },

  getRedirectUrl: (): string | null => {
    return localStorage.getItem('redirectUrl');
  },

  storeRedirectUrl: (inviteUrl: string): void => {
    return localStorage.setItem('redirectUrl', inviteUrl);
  },

  convertParams: (data: Record<string, any>): ISearch => {
    const params: ISearch = {
      keyword: data.keyword,
      skip: data.skip,
      limit: data.limit,
      sortBy: data.orderBy,
      sortType: data.order && data.order === 'ascend' ? 'asc' : 'desc',
      productId: data.productId,
      roleId: data.roleId,
      status: data.status,
    };
    return params;
  },

  convertStatus: (status: string): string => {
    if (!status) return '';

    return `<div class="column-status-wrapper ${statusClassName[status]}">
    <div class="status-dots"></div>
    <div class="status-text">${StatusColumnText[status]}</div>
  </div>`;
  },

  getFullName: (firstName?: string, lastName?: string): string => {
    return `${firstName ? firstName + ' ' : ''} ${lastName || ''}`;
  },

  convertArrItemToString: (arr: string[]): string => {
    if (!arr) return '';
    const rs = arr.map((item) => `"${item}"`);
    return rs.join(', ');
  },

  getBlockCodeEnvBE: (
    AUTH0_DOMAIN: string,
    AUTH0_CLIENT_ID: string,
    AUTH0_SECRET_KEY: string,
    AUTH0_AUDIENCE: string,
    SYSTEM_ADMIN_URL: string,
  ): string => {
    return `
    AUTH0_ISSUER=https://${AUTH0_DOMAIN}/
    AUTH0_CLIENT_ID=${AUTH0_CLIENT_ID}
    AUTH0_SECRET_KEY=${AUTH0_SECRET_KEY}
    AUTH0_AUDIENCE=${AUTH0_AUDIENCE}
    SYSTEM_ADMIN_URL=${SYSTEM_ADMIN_URL}
  `;
  },

  getBlockSAProviderBE: (
    SYSTEM_ADMIN_AUTH0_ISSUER: string,
    SYSTEM_ADMIN_URL: string,
    SYSTEM_ADMIN_CLIENT_ID: string,
    SYSTEM_ADMIN_SECRET_KEY: string,
    SYSTEM_ADMIN_AUTH0_SECRET_KEY: string,
  ): string => {
    return `
    SYSTEM_ADMIN_AUTH0_ISSUER=https://${SYSTEM_ADMIN_AUTH0_ISSUER}/
    SYSTEM_ADMIN_URL=${SYSTEM_ADMIN_URL}
    SYSTEM_ADMIN_CLIENT_ID=${SYSTEM_ADMIN_CLIENT_ID}
    SYSTEM_ADMIN_SECRET_KEY=${SYSTEM_ADMIN_SECRET_KEY}
    SYSTEM_ADMIN_AUTH0_SECRET_KEY=${SYSTEM_ADMIN_AUTH0_SECRET_KEY}
  `;
  },

  getBlockCodeEnvFE: (
    AUTH0_DOMAIN: string,
    AUTH0_CLIENT_ID: string,
    CALLBACK_URL: string,
    AUTH0_AUDIENCE: string,
  ): string => {
    return `
    REACT_APP_AUTH0_DOMAIN=${AUTH0_DOMAIN}
    REACT_APP_AUTH0_CLIENT_ID=${AUTH0_CLIENT_ID}
    REACT_APP_AUTH0_AUDIENCE=${AUTH0_AUDIENCE}
  `;
  },

  getBlockCodeReact: (
    AUTH0_DOMAIN: string,
    AUTH0_CLIENT_ID: string,
    CALLBACK_URL: string[],
    AUTH0_AUDIENCE: string,
  ): string => {
    return ` // src/index.js
    import React from 'react';
    import ReactDOM from 'react-dom';
    import { Auth0Provider } from '@auth0/auth0-react';
    import App from './App';
    
    ReactDOM.render(
      <Auth0Provider
        domain={"${AUTH0_DOMAIN}"} // Auth0 domain, example: ${AUTH0_DOMAIN}
        clientId={"${AUTH0_CLIENT_ID}"} // Your Auth0 Application ID, example: ${AUTH0_CLIENT_ID}
        useRefreshTokens={true} // Auto get refresh token 
        authorizationParams={{ 
          audience: "${AUTH0_AUDIENCE}", // Your Audience, example ${AUTH0_AUDIENCE}
          redirect_uri: "${
            CALLBACK_URL[0]
          }" // The callback url that you set to product, example: ${CALLBACK_URL.join(', ')}
        }} 
      >
        <App />
      </Auth0Provider>,
      document.getElementById('app')
    );`;
  },
};

export default Helper;
