import { FunctionComponent } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import LayoutBlank from 'containers/Layout/blank';

interface PublicRoutesProps {
  exact: boolean | undefined;
  path: string;
  component: FunctionComponent<RouteComponentProps>;
}

const PublicRoutes = ({ exact, path, component }: PublicRoutesProps): JSX.Element => {
  return (
    <LayoutBlank path={path}>
      <Route exact={exact} path={path} component={component} />
    </LayoutBlank>
  );
};

export default PublicRoutes;
