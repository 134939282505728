import { createSlice } from '@reduxjs/toolkit';
import React from 'react';

export interface GeneralState {
  loading: boolean;
  isCheckPermiss: boolean;
  confirmModal: {
    visible: boolean;
    data: Record<string, React.ReactNode>;
  };
  importCSVModal: {
    visible: boolean;
    data: Record<string, React.ReactNode>;
  };
}

const initialState = {
  loading: false,
  isCheckPermiss: false,
  confirmModal: {
    visible: false,
    data: {},
  },
  importCSVModal: {
    visible: false,
    data: {},
  },
} as GeneralState;

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setFormModal(state, action) {
      const { payload = {} } = action;
      return { ...state, [payload.key]: payload };
    },
    resetDataFormModal(state, action) {
      const { payload = {} } = action;
      return { ...state, [payload.key]: null };
    },
    setConfirmModal(state, action) {
      return {
        ...state,
        confirmModal: {
          visible: action.payload.visible,
          data: action.payload.data || state.confirmModal.data,
        },
      };
    },
    resetConfirmModal(state) {
      return {
        ...state,
        confirmModal: {
          ...state.confirmModal,
          data: {},
        },
      };
    },
    setImportCSVModal(state, action) {
      return {
        ...state,
        importCSVModal: {
          visible: action.payload.visible,
          data: action.payload.data || state.importCSVModal.data,
        },
      };
    },
    showLoading(state) {
      return { ...state, loading: true };
    },
    hideLoading(state) {
      return { ...state, loading: false };
    },
    setIsCheckPermission(state, action) {
      return { ...state, isCheckPermiss: action.payload };
    },
  },
});

export const {
  setFormModal,
  resetDataFormModal,
  setConfirmModal,
  resetConfirmModal,
  setImportCSVModal,
  showLoading,
  hideLoading,
  setIsCheckPermission,
} = generalSlice.actions;
export default generalSlice.reducer;
