const TRANSLATIONS_JP = {
  global: {
    noData: 'No data',
    yes: 'はい',
    no: 'いいえ',
  },
  login: {
    login: 'サインイン',
    email: 'メールアドレス',
    password: 'パスワード',
  },
  menuBar: {
    dashboard: 'ダッシュボード',
    bookings: '予約',
    sales: '販売',
    providerEarnings: 'プロバイダーの収益',
    providerPayout: 'プロバイダーの支払い',
    providers: 'サービスプロバイダー',
    customers: 'お客様',
    services: 'サービス',
    support: 'サポート',
    userReport: 'ユーザーレポート',
    systemUsers: 'システムユーザー',
    users: 'ユーザー',
    roles: '役割',
    activityLogs: 'アクティビティログ',
    settings: '設定',
    products: '製品',
  },
  actionButton: {
    addCoach: 'コーチを追加',
    uploadAndContinue: 'アップロードして続行',
    addFile: 'ファイルを追加',
    createAccounts: 'アカウントを作成する',
    replaceFile: 'ファイルを置き換える',
    cancel: 'キャンセル',
    submit: '送信',
    ok: 'わかった',
    createAccount: 'アカウントを作成する',
    changePassword: 'パスワードを変更する',
    change: '変化する',
    deactivate: '非アクティブ化',
    active: 'アクティブ',
    delete: '消去',
    prev: '前へ',
    next: '次',
    showToday: '今日見せて',
    showThisWeek: '今週見せて',
    showThisMonth: '今月表示',
    showThisCycle: 'このサイクルを表示',
    view: 'ビュー',
    invite: '招待',
    add: '追加',
  },
  validation: {
    required: '必須項目です',
    invalidEmail: 'このメールアドレスは無効です',
    invalidWeb: '無効なURLです',
    INVALID: '無効です',
    confirmPassNotMatch: 'The confirm password does not match',
    passRule: 'アルファベットの大文字小文字を含む英数字8文字以上で設定してください',
    registeredEmail: 'このメールアドレスは登録済みです',
    alphabetRequired: 'Name may include only alphabetical characters.',
    maxCharacters: 'Max length is 10 characters',
  },
  example: {
    email: 'メール',
    id: 'ID',
  },
  tableForm: {
    search: '検索',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Please enter',
    selectPlaceholder: 'Please select',
    exportResultCSV: '結果のエクスポート（CSV）',
  },
  alert: {
    clear: 'Clear',
    sessionExpired: 'Session expired',
    loginRequired: 'Your session has expired. Please log in again',
  },
  tableToolBar: {
    leftPin: 'Pin to left',
    rightPin: 'Pin to right',
    noPin: 'Unpinned',
    leftFixedTitle: 'Fixed the left',
    rightFixedTitle: 'Fixed the right',
    noFixedTitle: 'Not Fixed',
    reset: 'Reset',
    columnDisplay: 'Column Display',
    columnSetting: 'Settings',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Refresh',
    density: 'Density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact',
  },
  button: {
    goBack: 'Go Back',
  },
  profile: {
    fullName: '氏名',
    accountStatus: 'アカウントのステータス',
    lastLogin: '前回のログイン',
    phoneticName: 'フリガナ',
    email: 'Eメール',
    emailAddress: 'メールアドレス',
    birthday: '誕生日',
    phone: '電話番号',
    phoneNumber: '電話番号',
    lastName: '姓',
    firstName: '名',
    phoneticLastName: 'フリガナ(姓)',
    phoneticFirstName: 'フリガナ(名)',
    displayName: '表示名',
    profilePicture: 'プロフィール写真',
    gender: '性別',
    male: '男',
    female: '女性',
    unanswered: '未回答',
    registerDate: '登録日',
    region: '地域',
    prefecture: '都道府県',
    zipCode: '郵便番号',
    businessImages: 'ビジネス画像',
    profileText: 'プロフィール文章',
    businessName: '商号',
    businessNumber: '事業番号',
    addressLine1: '番地',
    municipality: '市区町村',
    addressLine2: '建物名等',
    addressPrivacySettings: 'サロン住所公開設定',
    addressPrivacy: 'プライバシーに対処する',
    mapLocation: '地図の場所',
    accountHolder: '銀行口座（口座名）',
    accountNumber: '銀行口座（口座番号）',
    accountType: '口座の種類',
    bankBookImage: '通帳画像',
    financialInstitution: '金融機関',
    financialInstitutionName: '銀行口座（金融機関名）',
    bankCode: '金融機関コード',
    branchName: '銀行口座（支店名）',
    branchCode: '支店コード',
    username: 'ユーザー名',
    password: 'パスワード',
    role: '役割',
    newPassword: '新しいパスワード',
    permissionBooking: '予約',
    permissionProvider: 'プロバイダー管理',
    permissionCustomer: '顧客管理',
    permissionService: 'サービス管理',
    description: '説明',
  },
  detail: {
    detail: '詳細',
    paymentType: '払いの種類',
    paymentID: '支払い ID',
    customerName: '顧客名',
    totalChargedToCard: 'カードに請求された合計',
    cardEndingIn: 'で終わるカード',
    chargedOn: '充電済み',
    menuItem: 'メニュー項目',
    amount: '額',
    totalAmount: '合計金額',
    subtotal: '小計',
    discount: '割引',
    lastUpdated: '最終更新',
    description: '説明',
    servicePhoto: 'サービス写真',
    displayOrder: '表示順',
  },
  common: {
    tags: 'タグ',
    disable: '無効にする',
    enable: '有効',
    confirm: '確認',
    ruSure: '本気ですか？',
    create: '作成',
    createNewAccount: '新しいアカウントを作成する',
    optional: 'オプション',
    upload: 'アップロード',
    createSystemAccount: 'システムアカウントを作成する',
    editSystemAccount: 'システムアカウントを編集する',
    createRole: '役割の作成',
    delete: '消去',
    createProduct: '製品を作成',
    editProduct: '商品を編集',
    secretKey: '秘密鍵',
    createdAt: 'で作成',
    edit: '編集',
    information: '情報',
    product: '製品',
    role: '役割',
    permission: '許可',
    register: '登録',
    logout: 'ログアウト',
    notPer: 'ごめん！許可がありません',
    notFound: 'ごめん！ページが見つかりません',
    logoutText: 'ログアウト',
  },
  action: {
    changeStatus: 'ステータスを変更する',
    cancel: 'キャンセル',
    duplicate: '複製',
    viewDetails: '詳細を見る',
  },
  status: {
    pending: '保留中',
    confirmed: '確認済み',
    cancelled: 'キャンセル',
    complete: '完了',
    deleted: '削除されました',
    active: 'アクティブ',
    disabled: '無効',
  },
  title: {
    serviceProviders: 'サービスプロバイダー',
    profile: 'プロフィール',
    booking: '予約',
    services: 'サービス',
    schedule: '予定',
    sales: '販売',
    reviews: 'レビュー',
    basicInformation: '基本情報',
    businessInformation: 'ビジネス情報',
    bankInformation: '銀行情報',
    customers: '顧客',
    genderatedContent: '性別コンテンツ',
    bookingInformation: '予約情報',
    paymentInformation: '支払情報',
    statusHistory: 'ステータス履歴',
    requested: 'リクエスト済み',
    confirmation: '確認',
    createService: 'サービスを作成する',
    editCategory: 'カテゴリを編集',
    usernameAndPassword: 'ユーザー名とパスワード',
    systemAccount: 'システムアカウント',
    role: '役割',
    changePassword: 'パスワードを変更する',
    deactivate: '非アクティブ化',
    active: 'アクティブ',
    delete: '消去',
    nameAndDescription: '名前と説明',
    permission: '許可',
    earnings: '収益',
    currentPayoutCycleEarnings: '現在の支払いサイクルの収益',
    historyEarnings: '過去の収益',
    earningDetail: '収益の詳細',
    transactions: '取引',
    products: '製品',
  },
  description: {
    statusHistoryRequested: 'お客様が予約をリクエストしました',
    statusHistoryConfirmation: 'プロバイダーの確認を待っています',
    statusHistoryComplete: '顧客はサービスを受けました',
    changePassword: 'このアカウントのパスワードを変更してください。',
    deactivateAccount: 'アカウントは無効になり、ユーザーはアクセスできなくなります。',
    activeAccount: 'アカウントがアクティブになり、ユーザーがアクセスできるようになります。',
    deleteAccount: 'アカウントは削除されます。',
    activeRole: 'ロールがアクティブになり、ユーザーがアクセスできるようになります。',
    deactivateRole: 'ロールは非アクティブ化され、ユーザーはアクセスできなくなります。',
    deleteRole: '役割は削除されます。',
  },
  tableColumn: {
    view: 'ビュー',
    edit: '編集',
    active: 'アクティブ',
    deactive: '非アクティブ化',
    delete: '消去',
    archive: '記録',
    unArchive: 'アーカイブ解除',
    settingAuth0: 'Auth0の初期化',
  },
  columnTitle: {
    numberId: 'ID #',
    avatar: 'アバター',
    fullName: 'フルネーム',
    email: 'メールアドレス',
    phoneNumber: '電話番号',
    registerDate: '登録日',
    status: 'ステータス',
    action: 'アクション',
    bookingId: '予約 ID',
    lastUpdate: '最後の更新',
    bookingDate: '予約 日',
    provider: 'プロバイダー',
    customer: 'お客様',
    total: '合計',
    categoryName: 'カテゴリ名',
    serviceName: 'サービス名',
    duration: '間隔',
    price: '価格',
    category: 'カテゴリー',
    tags: 'タグ',
    providerName: 'プロバイダー',
    datePayout: '日にち',
    fromDate: '日付から',
    toDate: '現在まで',
    totalGross: '総収入',
    totalFee: '合計料金',
    totalNet: 'ネット合計',
    name: '名前',
    role: '役割',
    description: '説明',
    activityTime: '時間',
    message: 'メッセージ',
    triggeredBy: '引き起こされた',
    earningGross: '総収入',
    earningNet: '収益ネット',
    from: 'から',
    to: 'に',
    date: '日にち',
    transactionId: 'トランザクションID',
    transactionDate: '日にち',
    amount: '額',
    amountNet: '純額',
  },
  extraContent: {
    importCSV: 'インポート（CSV）',
    exportAllCSV: 'すべてエクスポート（CSV）',
    inviteUser: 'ユーザーを作成',
    createBooking: '予約を作成する',
    createCategory: 'カテゴリを作成する',
    addService: 'サービスを追加',
    createRole: '役割を作成する',
    createProduct: '製品を作成',
  },
  placeholder: {
    searchDefault: 'ユーザー名、ID、登録済みメールを検索',
    searchBooking: '予約 ID、顧客、プロバイダーを検索',
    searchService: '検索サービス名',
    searchProviderEarning: 'プロバイダーの電子メール、ID、ユーザー名、電話で検索',
    searchPayout: 'ID、名前で支払いを検索',
    searchProduct: '製品名を検索',
    searchKeyword: 'キーワードで探す',
  },
  type: {
    protect: '守る',
    public: '公衆',
    saving: '保存',
    standard: '標準',
  },
  importCSVModal: {
    title: 'CSVのインポートによるプロバイダーの作成',
    download: 'ダウンロード',
    sampleCSVTemplate: 'サンプルCSVテンプレート',
    seeExample: '例を参照してください。',
    dragDropCSV: 'ここにCSVファイルをドラッグアンドドロップします',
    overwriteDuplicateEmail: 'メールアドレスが重複しているアカウントを上書きします。',
    addedFile: '追加されたファイル',
    importedCSVNotification: `複数のプロバイダーアカウントを作成します。プレビューして、コンテンツが正しく表示されることを確認してください。
    そうでない場合は、ここで調整できます。`,
    youWillImport: 'インポートします',
    numberAccount: 'アカウント',
    withTotal: '合計などでファイルの詳細に関するテキストをコピーします。',
    importingDataNotificationTitle: 'データベース内のプロバイダーリストへの現在のインポートデータ',
    importingDataNotificationContent: `これは、完了するのにしばらく時間がかかる可能性があります。よろしければ、
    このダイアログボックスを閉じて、作業を続行できます。`,
    closeImportCSVTitle: 'CSVポップアップのインポートを閉じますか？',
    closeImportCSVContent: 'CSVのインポートをキャンセルしますか。',
  },
  role: {
    administrator: '管理者',
    custom: 'カスタム',
    provider: 'プロバイダー',
    name: 'ロール名',
    code: '役割コード',
    permissions: '権限',
    level: 'レベル',
    levelDes: 'レベル（数字が小さいほど優先度が高い）',
    editRole: '役割を編集',
    root: '根',
    actions: '行動',
    note: 'ノート',
    noteContent: 'レベル - 数値が小さいほど優先度が高い',
    select: '役割を検索して選択する',
    selectOnly: '役割を選択',
    assignRole: '役割の割り当て',
    rootRole: 'ルートの役割',
  },
  providerConfirmModal: {
    disabled: 'このプロバイダーアカウントを無効にしますか？',
    active: 'このプロバイダーアカウントを有効にしますか？',
    delete: 'このプロバイダーアカウントを削除しますか？',
  },
  customerConfirmModal: {
    disabled: 'この顧客アカウントを無効にしますか？',
    active: 'この顧客アカウントを有効にしますか？',
    delete: 'この顧客アカウントを削除しますか？',
  },
  dateViewMode: {
    today: '今日',
    thisWeek: '今週',
    thisMonth: '今月',
    thisCycle: 'このサイクル',
    customDate: 'カスタム日付',
    day: '日',
    week: '週',
    month: '月',
    payoutCycle: '支払いサイクル',
    custom: 'カスタム',
  },
  changePasswordModal: {
    title: 'あなたのパスワードをリセット？',
    content: 'パスワードをリセットしますか？ パスワードのリセットリンクをメールで送信します。',
    contentForUser:
      'このユーザーのパスワードをリセットしますか？ このユーザーのメールアドレスにパスワードのリセットリンクを送信します。',
  },
  payout: {
    payout: '支払い',
    payoutDate: '支払い日',
    earning: '収入を得る',
    commissionFee: '手数料',
    transferFee: '転送料',
    transferTotal: '合計転送',
    providerAddress: 'プロバイダーアドレス',
    staffNumber: 'スタッフ番号',
    chargedBooking: '有料予約',
    bankName: '銀行名',
    bankNumber: '銀行番号',
    bankUserName: '銀行のユーザー名',
    staff: 'スタッフ',
    chargedDate: '課金日',
  },
  service: {
    services: 'サービス',
    addService: 'サービスを追加',
  },
  notification: {
    notificationTitleText: '通知',
    notificationMarkReadText: 'すべてを既読にする',
    notificationLoadedText: 'あなたはそれをすべて見ました',
    notificationLoadingText: '読み込み中...',
    notificationNoDataText: 'データなし',
  },
  product: {
    products: '製品',
    product: '製品',
    productName: '名前',
    productNameJa: '日本製品名',
    adminSiteUrl: '管理サイトの URL',
    adminSiteUrlCallBack: '管理サイトの URL コールバック',
    productId: '製品番号',
    authen: '認証',
    productCode: 'コード',
    domain: 'ドメイン',
    clientId: 'クライアントID',
    appId: 'アプリ ID',
    apiId: 'Api id',
    tokenSecretKey: 'トークン秘密鍵',
    audience: '観客',
    logo: 'ロゴ',
    logoUrl: 'ロゴ URL',
    tokenExpire: 'トークンの有効期限 (秒)',
    refreshTokenExpire: '更新トークンの有効期限 (秒)',
    tokenExpireNotSecond: 'トークンの有効期限',
    refreshTokenExpireNotSecond: '更新トークンの有効期限',
    loginUrlCallback: 'ログイン コールバック URL',
    logoutCallbackUrl: 'ログアウト コールバック URL',
    auth0: 'Auth0',
    select: '製品を検索して選択する',
    seconds: '(秒)',
    integrationExp: '統合コードの例',
    auth0Domain: 'Auth0 ドメイン',
    auth0ManagementClientId: 'Auth0管理クライアントID',
    auth0ManagementSecretKey: 'Auth0管理シークレットキー',
    auth0ManagementAudience: 'Auth0管理オーディエンス',
    addUrl: 'URLを追加',
  },
  user: {
    users: 'ユーザー',
    invite: '招待',
    inviteUser: 'ユーザーを招待',
  },
  message: {
    created: '正常に作成されました。',
    updated: '正常に更新されました！',
    deleted: '正常に削除されました!',
    invited: '招待成功！',
    verify: '検証成功！',
    verifyExist: 'ユーザーはすでにシステムに存在します',
    assigned: '割り当てられました。',
    unAssigned: '割り当て解除に成功しました!',
    verifyInvite: 'ユーザーの招待を確認する',
    required: 'これは必要項目です',
    validateDomain: 'これは有効なドメインでなければなりません',
    validateUrl: 'これは有効な URL でなければなりません',
    maxPrefix: 'これはせいぜい',
    character: '文字',
    typeNumber: 'これは数字でなければなりません',
    deleteTitle: 'このアイテムを削除',
    deleteDes: 'このアイテムを削除しますか?',
    registerSuccess: 'あなたが正常に登録されています',
    archived: '正常にアーカイブされました!',
    actived: '正常にアクティブ化されました!',
    areYouSure: '本気ですか？',
    areYouSureDes: 'これを実行してもよろしいですか？',
    initialAuth0SettingConfirm: 'auth0の設定を初期化しますか?',
    initialAuth0SettingSuccess: 'auth0 の初期設定が正常に完了しました',
  },
};

export default TRANSLATIONS_JP;
