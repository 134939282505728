import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout as LayoutPage } from 'admin-console-library';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { useHistory } from 'react-router-dom';
import i18n from 'i18n';

import { useAppDispatch, useAppSelector } from 'store';
import { changePasswordRequest } from 'providers/AuthProvider/slice';
import { setConfirmModal } from 'providers/GeneralProvider/slice';
import { AUTH0_CLIENT_ID } from 'utils/config';
import Helper from 'utils/helpers';
import { indexRoutes, Route } from 'routes';
import { changeLanguage } from 'providers/LangProvider/slice';
import { langOptions } from 'utils/constants';

const LayoutContainer = ({
  children,
  path,
}: {
  children: JSX.Element;
  path: string;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { logout, user } = useAuth0();
  const { t } = useTranslation('alert');
  const history = useHistory();
  const { location } = history;
  const lang = useAppSelector((state) => state.lang);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const routes = [] as any;
  // add translation for menu
  indexRoutes.forEach((itemRoute) => {
    const itemRouteCustom = { ...itemRoute };
    if (itemRoute.name) {
      itemRouteCustom.name = t(itemRoute.name);
    }
    if (itemRoute.routes) {
      itemRoute.routes.forEach((itemSubRoute, index) => {
        if (itemSubRoute.name) {
          _set(itemRouteCustom, `routes.${index}.name`, t(itemSubRoute.name));
        }
      });
    }
    routes.push(itemRouteCustom);
  });

  // const getToken = async () => {
  //   try {
  //     const token = await getAccessTokenSilently({});
  //     return token;
  //   } catch (e) {
  //     if (e.error === 'login_required') {
  //       alert(t('loginRequired'));
  //     } else if (e.error === 'invalid_grant' && !window.sessionExpiredAlert) {
  //       window.sessionExpiredAlert = true;
  //       loginWithRedirect({
  //         appState: {
  //           returnTo: `${window.location.pathname}${window.location.search}`,
  //         },
  //       });
  //     } else {
  //       console.error(e);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const isChangePassword = Helper.getChangePassowrd();
  //     if (isChangePassword) {
  //       getToken();
  //     }
  //   }, GET_ACCESS_TOKEN_MINUTE_MS);

  //   return () => clearInterval(interval);
  // });

  const clickLogo = () => {
    history.push('/products');
  };

  useEffect(() => {
    document.querySelector('.ant-pro-sider-logo')?.addEventListener('click', clickLogo);
  }, []);

  const handleLogout = () => {
    logout({
      returnTo: window.location.origin,
    });
    Helper.removeChangePassowrd();
  };

  const handleChangePassword = () => {
    dispatch(
      setConfirmModal({
        visible: true,
        data: {
          title: t('changePasswordModal:title'),
          content: t('changePasswordModal:content'),
          onOk: () => {
            dispatch(
              changePasswordRequest({
                client_id: AUTH0_CLIENT_ID,
                email: user?.email,
                connection: 'Username-Password-Authentication',
              }),
            );
            Helper.setChangePassowrd();
          },
        },
      }),
    );
  };

  const handleChangeLang = (langValue) => {
    i18n.changeLanguage(langValue);
    dispatch(changeLanguage({ current: langValue }));
  };

  const handleClickLeftMenuItem = (pathValue) => {
    history.push(pathValue);
  };

  const menuHeader = [
    {
      key: 'LOGOUT',
      label: t('common:logoutText'),
      func: handleLogout,
    },
  ];

  return (
    <LayoutPage
      currentUser={{
        picture: _get(user, 'picture', ''),
        name: _get(user, 'name', ''),
      }}
      pathname={location.pathname}
      route={{
        path: '/',
        routes: routes.filter((route: Route) => route.menu),
      }}
      lang={lang.current}
      langOptions={langOptions}
      logoImage="/c2c-logo.png"
      handleLogout={handleLogout}
      handleChangePassword={handleChangePassword}
      handleChangeLang={handleChangeLang}
      handleClickLeftMenuItem={handleClickLeftMenuItem}
      menuHeader={menuHeader}
    >
      {children}
    </LayoutPage>
  );
};
export default LayoutContainer;
