import { createSlice } from '@reduxjs/toolkit';

export interface RoleState {
  // PLOP: Add state interface below -- DO NOT ERASE
  detail: Record<string, string | number | Array<string>>;
  loading: boolean;
  list: never[];
  total: number;
  deleteRoleLoading: boolean;
  deactivateRoleLoading: boolean;
}

const initialState: RoleState = {
  // PLOP: Add initial state below -- DO NOT ERASE
  detail: {},
  loading: false,
  list: [],
  total: 0,
  deleteRoleLoading: false,
  deactivateRoleLoading: false,
};

const roleState = createSlice({
  name: 'role',
  initialState,
  reducers: {
    reset(state, action) {
      return { ...state, ...initialState };
    },
    // PLOP: Add reducers below -- DO NOT ERASE
    getRoleDetailRequest(state, action) {
      return { ...state, loading: true };
    },
    getRoleDetailSuccess(state, action) {
      const { detail } = action.payload;

      return { ...state, loading: false, detail };
    },
    getRoleDetailFailure(state, action) {
      return { ...state, loading: false };
    },
    getRoleListRequest(state, action) {
      return { ...state, loading: true };
    },
    getRoleListSuccess(state, action) {
      const { list, total } = action.payload;
      return { ...state, loading: false, list, total };
    },
    getRoleListFailure(state, action) {
      return { ...state, loading: false };
    },

    createRoleRequest(state, action) {
      return { ...state, loading: true };
    },
    createRoleSuccess(state, action) {
      return { ...state, loading: false };
    },
    createRoleFailure(state, action) {
      return { ...state, loading: false };
    },

    editRoleRequest(state, action) {
      return { ...state, loading: true };
    },
    editRoleSuccess(state, action) {
      return { ...state, loading: false };
    },
    editRoleFailure(state, action) {
      return { ...state, loading: false };
    },

    deleteRoleRequest(state, action) {
      return { ...state, deleteRoleLoading: true };
    },
    deleteRoleSuccess(state, action) {
      return { ...state, deleteRoleLoading: false };
    },
    deleteRoleFailure(state, action) {
      return { ...state, deleteRoleLoading: false };
    },

    deactivateRoleRequest(state, action) {
      return { ...state, deactivateRoleLoading: true };
    },
    deactivateRoleSuccess(state, action) {
      return { ...state, deactivateRoleLoading: false };
    },
    deactivateRoleFailure(state, action) {
      return { ...state, deactivateRoleLoading: false };
    },
  },
});

export const {
  // PLOP: Add actions export below -- DO NOT ERASE
  getRoleDetailRequest,
  getRoleDetailSuccess,
  getRoleDetailFailure,
  getRoleListRequest,
  getRoleListSuccess,
  getRoleListFailure,
  createRoleRequest,
  createRoleSuccess,
  createRoleFailure,
  editRoleRequest,
  editRoleSuccess,
  editRoleFailure,
  deleteRoleRequest,
  deleteRoleSuccess,
  deleteRoleFailure,
  deactivateRoleRequest,
  deactivateRoleSuccess,
  deactivateRoleFailure,
  reset,
} = roleState.actions;

export default roleState.reducer;
