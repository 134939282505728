import { createSlice } from '@reduxjs/toolkit';
import { LANGUAGE_DISPLAY } from 'utils/config';
import Helper from 'utils/helpers';
export interface LangState {
  current: string;
}

const initialState = {
  current: Helper.getLang() || LANGUAGE_DISPLAY,
};

const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    changeLanguage(state, action) {
      return { ...state, current: action.payload.current };
    },
  },
});

export const { changeLanguage } = langSlice.actions;

export default langSlice.reducer;
