import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  // PLOP: Add state interface below -- DO NOT ERASE
  loading: boolean;
  list: unknown[];
  total: number;
  deleteUserLoading: boolean;
  archiveUserLoading: boolean;
  assignRoleLoading: boolean;
  unAssignRoleLoading: boolean;
  changePasswordUserLoading: boolean;
  loadingUnAssignRoleByUser: boolean;
  listUnAssignRoleByUser: unknown[];
  detail: Record<string, string | number | Array<string>>;
}

const initialState: UserState = {
  // PLOP: Add initial state below -- DO NOT ERASE
  loading: false,
  list: [],
  total: 0,
  deleteUserLoading: false,
  archiveUserLoading: false,
  assignRoleLoading: false,
  unAssignRoleLoading: false,
  changePasswordUserLoading: false,
  loadingUnAssignRoleByUser: false,
  listUnAssignRoleByUser: [],
  detail: {},
};

const userState = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset(state, action) {
      return { ...state, ...initialState, loading: true };
    },
    // PLOP: Add reducers below -- DO NOT ERASE
    getUserDetailRequest(state, action) {
      return { ...state, loading: true };
    },
    getUserDetailSuccess(state, action) {
      const { detail } = action.payload;
      return { ...state, loading: false, detail };
    },
    getUserDetailFailure(state, action) {
      return { ...state, loading: false };
    },
    getUserListRequest(state, action) {
      return { ...state, loading: true };
    },
    getUserListSuccess(state, action) {
      const { list, total } = action.payload;
      return { ...state, loading: false, list, total };
    },
    getUserListFailure(state, action) {
      return { ...state, loading: false };
    },

    inviteUserRequest(state, action) {
      return { ...state, loading: true };
    },
    inviteUserSuccess(state, action) {
      return { ...state, loading: false };
    },
    inviteUserFailure(state, action) {
      return { ...state, loading: false };
    },

    verifyTokenInviteUserRequest(state, action) {
      return { ...state, loading: true };
    },
    verifyTokenInviteUserSuccess(state, action) {
      return { ...state, loading: false };
    },
    verifyTokenInviteUserFailure(state, action) {
      return { ...state, loading: false };
    },

    registerUserRequest(state, action) {
      return { ...state, loading: true };
    },
    registerUserSuccess(state, action) {
      return { ...state, loading: false };
    },
    registerUserFailure(state, action) {
      return { ...state, loading: false };
    },

    editUserRequest(state, action) {
      return { ...state, loading: true };
    },
    editUserSuccess(state, action) {
      return { ...state, loading: false };
    },
    editUserFailure(state, action) {
      return { ...state, loading: false };
    },

    deleteUserRequest(state, action) {
      return { ...state, deleteUserLoading: true };
    },
    deleteUserSuccess(state, action) {
      return { ...state, deleteUserLoading: false };
    },
    deleteUserFailure(state, action) {
      return { ...state, deleteUserLoading: false };
    },

    archiveUserRequest(state, action) {
      return { ...state, archiveUserLoading: true };
    },
    archiveUserSuccess(state, action) {
      return { ...state, archiveUserLoading: false };
    },
    archiveUserFailure(state, action) {
      return { ...state, archiveUserLoading: false };
    },

    activeUserRequest(state, action) {
      return { ...state, activeUserLoading: true };
    },
    activeUserSuccess(state, action) {
      return { ...state, activeUserLoading: false };
    },
    activeUserFailure(state, action) {
      return { ...state, activeUserLoading: false };
    },

    changePasswordUserRequest(state, action) {
      return { ...state, changePasswordUserLoading: true };
    },
    changePasswordUserSuccess(state, action) {
      return { ...state, changePasswordUserLoading: false };
    },
    changePasswordUserFailure(state, action) {
      return { ...state, changePasswordUserLoading: false };
    },

    getUnAssignRoleByUserRequest(state, action) {
      return { ...state, loadingUnAssignRoleByUser: true };
    },
    getUnAssignRoleByUserSuccess(state, action) {
      const { listUnAssignRoleByUser } = action.payload;

      return {
        ...state,
        loadingUnAssignRoleByUser: false,
        listUnAssignRoleByUser,
      };
    },
    getUnAssignRoleByUserFailure(state, action) {
      return { ...state, loadingUnAssignRoleByUser: false };
    },

    assignRoleRequest(state, action) {
      return { ...state, assignRoleLoading: true };
    },
    assignRoleSuccess(state, action) {
      return { ...state, assignRoleLoading: false };
    },
    assignRoleFailure(state, action) {
      return { ...state, assignRoleLoading: false };
    },

    unAssignRoleRequest(state, action) {
      return { ...state, unAssignRoleLoading: true };
    },
    unAssignRoleSuccess(state, action) {
      return { ...state, unAssignRoleLoading: false };
    },
    unAssignRoleFailure(state, action) {
      return { ...state, unAssignRoleLoading: false };
    },

    addUserRequest(state, action) {
      return { ...state, loading: true };
    },
    addUserSuccess(state, action) {
      return { ...state, loading: false };
    },
    addUserFailure(state, action) {
      return { ...state, loading: false };
    },
  },
});

export const {
  // PLOP: Add actions export below -- DO NOT ERASE
  getUserDetailRequest,
  getUserDetailSuccess,
  getUserDetailFailure,
  getUserListRequest,
  getUserListSuccess,
  getUserListFailure,
  inviteUserRequest,
  inviteUserSuccess,
  inviteUserFailure,
  editUserRequest,
  editUserSuccess,
  editUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
  archiveUserRequest,
  archiveUserSuccess,
  archiveUserFailure,
  activeUserRequest,
  activeUserSuccess,
  activeUserFailure,
  changePasswordUserRequest,
  changePasswordUserSuccess,
  changePasswordUserFailure,
  verifyTokenInviteUserRequest,
  verifyTokenInviteUserSuccess,
  verifyTokenInviteUserFailure,
  registerUserRequest,
  registerUserSuccess,
  registerUserFailure,
  getUnAssignRoleByUserRequest,
  getUnAssignRoleByUserSuccess,
  getUnAssignRoleByUserFailure,
  assignRoleRequest,
  assignRoleSuccess,
  assignRoleFailure,
  unAssignRoleRequest,
  unAssignRoleSuccess,
  unAssignRoleFailure,
  reset,
  addUserRequest,
  addUserSuccess,
  addUserFailure,
} = userState.actions;

export default userState.reducer;
