import { store } from 'store';
import { hideLoading, showLoading } from 'providers/GeneralProvider/slice';

export default class Loading {
  static show(): void {
    store.dispatch(showLoading());
  }

  static hide(): void {
    store.dispatch(hideLoading());
  }
}
