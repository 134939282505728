import UserService from './user-services';
import FileService from './file-service';
import ProductService from './product-service';
import RoleService from './role-service';

const userService = new UserService('users');
const fileService = new FileService('file');
const productService = new ProductService('products');
const roleService = new RoleService('products');

export { userService, fileService, productService, roleService };
